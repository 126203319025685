import configure from "./configure";
import stringify from "./query";
import { getUserId } from "../utils/tokens";
import { getToken } from "services/utils";

const { setHeadersWithAuth, axios } = configure;

export const loginUser = (email, password) => {
  const body = { username: email, password };
  return axios.post("account/signin", body, configure.standardHeaders);
};

export const forgotPassword = (email) => {
  const body = { username: email };
  return axios.post("account/forgot-password", body, configure.standardHeaders);
};

export const registerUser = (form) => {
  const { firstName, lastName, email, password } = form;
  const body = { firstName, lastName, username: email, password };
  return axios.post("account/register", body, configure.standardHeaders);
};

export const registerProfessionalUser = (email) => {
  const body = { username: email };
  return axios.post("account/register-professional", body, configure.standardHeaders);
};

export const typeform = (form) => {
  const query = stringify(form);
  return axios.get(`account/typeform${query}`, configure.standardHeaders).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const typeformUpdate = (form) => {
  const { username, formType, responseId } = form;
  const body = { username, formType, responseId };
  return axios.patch("account/typeform-update", body, configure.standardHeaders).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const resetPassword = (password, resetToken) => {
  const body = { password, token: resetToken };
  return axios.post("account/reset-password", body, configure.standardHeaders);
};

export const getUserByToken = (resetToken) => {
  const body = { token: resetToken };
  return axios.post("account/get-user-by-token", body, configure.standardHeadersß);
};

export const getProfile = (userId, token, username) => {
  const headers = setHeadersWithAuth(token);
  const query = stringify({ userId, username });
  return axios.get(`account/profile${query}`, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const getReportUsers = (reportId, token) => {
  const headers = setHeadersWithAuth(token);
  const query = stringify({ reportId });
  return axios.get(`account/report${query}`, headers);
};

export const updateProfile = (userId, name, value, action) => {
  const body = { action: action, userId, option: name, value };
  return axios.post("account/update-account", body, configure.standardHeaders);
};

export const createCheckoutSession = (userId, packageTier) => {
  const body = { userId, packageTier };
  return axios.post("checkout/session", body, configure.standardHeaders);
};

export const getSubscriptionPrices = async () => {
  return axios.get("checkout/prices");
};

export const updateIcons = (username, icons) => {
  const body = { username, icons };
  return axios.post("account/update-icons", body, configure.standardHeaders);
};

export const addPlan = (userId, planId, token) => {
  const body = { userId, planId };
  const headers = setHeadersWithAuth(token);

  return axios.patch("account/add-plan", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const addReport = (userId, reportId, token) => {
  const body = { userId, reportId };
  const headers = setHeadersWithAuth(token);

  return axios.patch("account/add-report", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const addEmail = (userId, email, emailType, token) => {
  const body = { userId, email, emailType };
  const headers = setHeadersWithAuth(token);

  return axios.patch("account/add-email", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const deleteEmail = (userId, email, token) => {
  const body = { userId, email };
  const headers = setHeadersWithAuth(token);

  return axios.patch("account/delete-email", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const changeSubscription = async (subscriptionChange, reasons) => {
  // subscriptionChange - string = "cancel", "individual", "household", "business"
  // reasons - object
  const userId = await getUserId();
  const token = await getToken();
  const body = { userId, subscriptionChange, reasons };
  const headers = setHeadersWithAuth(token);

  return axios.patch("checkout/change", body, headers).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const usernameExists = (username) => {
  const query = stringify({ username });
  return axios.get(`account/username-exists${query}`).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const groupNewAccount = async ({
  userTypeformResponse,
  pregnancyTypeformResponse,
  group,
  additionalInformation,
  plansValues,
}) => {
  const body = {
    userTypeformResponse,
    pregnancyTypeformResponse,
    group,
    additionalInformation,
    plansValues,
  };
  return axios.post(`account/group-new-account`, body, configure.standardHeaders).then(
    (res) => {
      return res;
    },
    (error) => {
      return error;
    },
  );
};

export const updateRole = ({ username, role, token }) => {
  const headers = setHeadersWithAuth(token);
  const body = { username, role };
  return axios.patch("account/update-role", body, headers);
};

export const addNetworkToUser = ({ userId, gridId }) => {
  const body = { userId, gridId };
  return axios.patch("account/add-network", body, configure.standardHeaders);
};

export const getAllUserNetworks = ({ userId }) => {
  const query = stringify({ userId });
  return axios.get(`account/networks${query}`, configure.standardHeaders);
};

export const addGroupToUser = ({ userId, group }) => {
  const body = { userId, group };
  return axios.patch("account/add-group", body, configure.standardHeaders);
};

export const sendProfessionalEmail = ({ email }) => {
  const body = { email };
  return axios.post("account/send-professional-email", body, configure.standardHeaders);
};

export const mergeUsers = ({ userList, groupDomainList }) => {
  const body = { userList, groupDomainList };
  return axios.patch("account/merge-users", body, configure.standardHeaders);
};

export const exportUsers = ({ token }) => {
  const config = setHeadersWithAuth(token);
  return axios.get("account/export-users", {
    headers: config.headers,
    responseType: "blob",
  });
};
