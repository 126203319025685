import { getPlansArr, getReport } from "services/api";
import initialValues from "./initialValues";
import moment from "moment";

import model from "./model";
const {
  formField: {
    planOptions,
    withMultiplePremiumTiers,
    premiumTiersNumber,
    selectedPremiumTier,
    premiumTier,
    withWaiverPayment,
    waiverTiersNumber,
    waivers,
    selectedWaiverTier,
    waiverPaymentValue,
    waiverPaymentFrequency,
    withSpouseSurcharge,
    spousalSurcharge,
    spousalSurchargeFrequency,
    withTobaccoSurcharge,
    tobaccoSurcharge,
    tobaccoSurchargeFrequency,
    costFor,
    outOfNetworkCoverage,
    label,
    table,
    deductibles,
    frequency,
    startDate,
    OOPStarts,
    showIndividualInFamilyDeductibles,
    showIndividualInFamilyOOP,
    subjectToDeductible,
    serviceList,
    group,
    negativeValue,
    notSureAboutThese,
    enterAdvancedDetails,
    importedFromTypeform,
    emailFromTypeform,
    showNotSoSureAboutThese,
    dontAddMoreCategories,
    networkTable,
    enterNetworkRow2,
  },
} = model;

const forceObjectFromList = (list) => {
  try {
    const newObj = {};
    for (let key of Object.keys(list)) {
      if (list[key] !== null) {
        newObj[key] = list[key];
      }
    }
    return newObj;
  } catch (e) {
    return list;
  }
};

export const loadValues = async ({
  reportId,
  token,
  option,
  reportToVerify,
  startingPremiumTier,
  startingWaiverTier,
  displayName,
}) => {
  if (!option) {
    return initialValues;
  }
  const { data: reportInfo } = await getReport(reportToVerify ? reportToVerify : reportId, token);
  const report = reportInfo.report;

  if (reportToVerify) {
    option = "birthParent";
  }

  const {
    event: {
      general: { children },
    },
    type,
  } = report;

  const groupType =
    type === "individual" && !children
      ? "mo"
      : type === "household (with no spouse)" && children > 0
      ? "mc"
      : type === "household" && !children
      ? "ms"
      : type === "household" && children > 0
      ? "mf"
      : "mo";

  if (report[option].planIds.length === 0) {
    return { ...initialValues, [costFor.name]: groupType };
  }
  const { data: planInfo } = await getPlansArr(report[option].planIds, token, false);
  const plans = planInfo.plansArr;

  let serviceNames = [];

  const tableMap = plans.map((plan, index) => {
    const isHsaOrHraCompatible = plan.hsa.isHsaOrHraCompatible
      ? plan.hsa.isHsaOrHraCompatible
      : plan.hsa.isHra === null
      ? "no"
      : plan.hsa.isHra
      ? "HRA"
      : plan.hsa.hasBoth
      ? "both"
      : "HSA";

    const isHSACompatible = plan.hsa.isHSACompatible
      ? plan.hsa.isHSACompatible
      : isHsaOrHraCompatible === "HSA" || isHsaOrHraCompatible === "both" || plan.hsa.isHra === null
      ? "yes"
      : "";
    const depositedHSA = plan.hsa.frequency || 1;
    const hsaValues = {
      individualHSA: "",
      familyHSA: "",
      largeFamilyHSA: "",
    };
    const hraValues = {
      individualHRA: "",
      familyHRA: "",
      largeFamilyHRA: "",
    };

    if (isHsaOrHraCompatible === "HSA") {
      hsaValues.individualHSA = plan.hsa.tiers[1].individual * depositedHSA;
      hsaValues.familyHSA = plan.hsa.tiers[1].family * depositedHSA;
      hsaValues.largeFamilyHSA = plan.hsa.tiers[1].largeFamily * depositedHSA;
    } else if (isHsaOrHraCompatible === "HRA") {
      if (isHSACompatible === "yes") {
        hsaValues.individualHSA = plan.hsa.tiers[1].individual * depositedHSA;
        hsaValues.familyHSA = plan.hsa.tiers[1].family * depositedHSA;
        hsaValues.largeFamilyHSA = plan.hsa.tiers[1].largeFamily * depositedHSA;
        hraValues.individualHRA = plan.hsa.tiers[2].individual;
        hraValues.familyHRA = plan.hsa.tiers[2].family;
        hraValues.largeFamilyHRA = plan.hsa.tiers[2].largeFamily;
      } else {
        hraValues.individualHRA = plan.hsa.tiers[1].individual;
        hraValues.familyHRA = plan.hsa.tiers[1].family;
        hraValues.largeFamilyHRA = plan.hsa.tiers[1].largeFamily;
      }
    } else if (isHsaOrHraCompatible === "both") {
      hsaValues.individualHSA = plan.hsa.tiers[1].individual * depositedHSA;
      hsaValues.familyHSA = plan.hsa.tiers[1].family * depositedHSA;
      hsaValues.largeFamilyHSA = plan.hsa.tiers[1].largeFamily * depositedHSA;
      hraValues.individualHRA = plan.hsa.tiers[2].individual;
      hraValues.familyHRA = plan.hsa.tiers[2].family;
      hraValues.largeFamilyHRA = plan.hsa.tiers[2].largeFamily;
    }

    if (plan.services.length > 0) {
      serviceNames.push(...plan.services.map((service) => service.serviceName));
    }

    const services = plan.services;
    const servicesObject = {};
    for (const service of services) {
      servicesObject[service.serviceName] = {
        haveToPay: service.maternityCopayDetails.hospital
          ? service.inNetwork.coinsurance.rate
            ? "both"
            : "copay"
          : "coinsurance",
        inNetworkCoinsuranceRate: (service.inNetwork.coinsurance.rate * 100).toFixed(2) || 0,
        outOfNetworkCoinsuranceRate: (plan.outOfNetwork.coinsurance.rate * 100).toFixed(2) || 0,
        amount: service.maternityCopayDetails.hospital || 0,
        unit: service.maternityCopayDetails.unit !== "n/a" ? service.maternityCopayDetails.unit : "",
        maxUnits: service.maternityCopayDetails.maxUnits || "",
        inNetworkSubjectToDeductible: service.maternityCopayDetails.subjToDeductible ? "yes" : "no",
      };
    }

    return {
      id: plan.externalId,
      idx: index + 1,
      planName: plan.name,
      label: plan.network,
      insuranceNetwork: plan.insuranceCompany
        ? `${plan.broaderNetwork};${plan.insuranceCompany}`
        : plan.insuranceCompany === "Blue Cross/Blue Shield"
        ? "Blue Cross and/or Blue Shield (including Anthem)"
        : plan.broaderNetwork,
      tiers: forceObjectFromList(plan.premium.tiers),
      frequency: plan.premium.frequency,
      group: plan.group.split(" ").slice(0, -1).join(" "),
      startDate: plan.startDate,
      outOfNetworkCoverage: plan.outOfNetwork.coverage ? "yes" : "no",
      inNetworkIndividual: plan.inNetwork.individual.deductible != null ? plan.inNetwork.individual.deductible : "",
      inNetworkFamily: plan.inNetwork.family.deductible != null ? plan.inNetwork.family.deductible : "",
      outOfNetworkIndividual:
        plan.outOfNetwork.individual.deductible != null ? plan.outOfNetwork.individual.deductible : "",
      outOfNetworkFamily: plan.outOfNetwork.family.deductible != null ? plan.outOfNetwork.family.deductible : "",
      outOfNetworkAndInNetworkCrossAccumulate: plan.deductibles.cross.isAccumulatable ? "yes" : "no",
      familyType: plan.family.deductible.type || "",
      inNetworkIndividualInFamily:
        plan.inNetwork.family.individualDeductible != null ? plan.inNetwork.family.individualDeductible : "",
      hasinNetworkIndividualInFamily: plan.inNetwork.family.individualDeductible != null,
      outOfNetworkIndividualInFamily:
        plan.outOfNetwork.family.individualDeductible != null ? plan.outOfNetwork.family.individualDeductible : "",
      hasoutOfNetworkIndividualInFamily: plan.outOfNetwork.family.individualDeductible != null,
      inNetworkIndividualOOP: plan.inNetwork.individual.oopMax != null ? plan.inNetwork.individual.oopMax : "",
      inNetworkIndividualInFamilyOOP:
        plan.inNetwork.family.individualOopMax != null ? plan.inNetwork.family.individualOopMax : "",
      hasinNetworkIndividualInFamilyOOP: plan.inNetwork.family.individualOopMax != null,
      inNetworkFamilyOOP: plan.inNetwork.family.oopMax != null ? plan.inNetwork.family.oopMax : "",
      outOfNetworkIndividualOOP: plan.outOfNetwork.individual.oopMax != null ? plan.outOfNetwork.individual.oopMax : "",
      hasoutOfNetworkIndividualOOP: plan.outOfNetwork.individual.oopMax != null,
      outOfNetworkIndividualInFamilyOOP:
        plan.outOfNetwork.family.individualOopMax != null ? plan.outOfNetwork.family.individualOopMax : "",
      hasoutOfNetworkIndividualInFamilyOOP: plan.outOfNetwork.family.individualOopMax != null,
      outOfNetworkFamilyOOP: plan.outOfNetwork.family.oopMax != null ? plan.outOfNetwork.family.oopMax : "",
      hasoutOfNetworkFamilyOOP: plan.outOfNetwork.family.oopMax != null,
      familyOOPType: plan.family.oopMax.type || "",
      services: servicesObject,
      isHSACompatible,
      enrollHealthCareFSA: plan.hsa.isFsa ? "yes" : "no",
      isHsaOrHraCompatible,
      individualHSA: hsaValues.individualHSA != null ? hsaValues.individualHSA : "",
      familyHSA: hsaValues.familyHSA != null ? hsaValues.familyHSA : "",
      largeFamilyHSA: hsaValues.largeFamilyHSA != null ? hsaValues.largeFamilyHSA : "",
      depositedHSA: plan.hsa.frequency || "",
      individualHRA: hraValues.individualHRA != null ? hraValues.individualHRA : "",
      familyHRA: hraValues.familyHRA != null ? hraValues.familyHRA : "",
      largeFamilyHRA: hraValues.largeFamilyHRA != null ? hraValues.largeFamilyHRA : "",
      towardHRA: plan.hsa.toward || "",
    };
  });

  const getNumberOfWaivers = (plans) => {
    if (!plans[0] || !plans[0].premium || !plans[0].premium.waivers || !plans[0].premium.waivers["1"]) {
      return 0;
    }

    for (let key of Object.keys(plans[0].premium.waivers)) {
      if (plans[0].premium.waivers[key] == null) {
        delete plans[0].premium.waivers[key];
      }
    }
    return Object.keys(plans[0].premium.waivers).length;
  };

  const getNumberOfTiers = (table) => {
    if (!table || !table[0] || !table[0].tiers) {
      return 0;
    }

    for (let key of Object.keys(table[0].tiers)) {
      if (table[0].tiers[key] == null) {
        delete table[0].tiers[key];
      }
    }

    return Object.keys(table[0].tiers).filter((key) => key !== null).length;
  };

  const numberOfTiers = getNumberOfTiers(tableMap);

  const numberOfWaivers = getNumberOfWaivers(plans);

  const broaderNetworks = tableMap.map((el) => el.insuranceNetwork);

  const waiversObj = plans[0] && plans[0].premium && plans[0].premium.waivers ? plans[0].premium.waivers : {};

  const FSInitialValues = {
    FSMaxUnits: 0,
    FSMaxAmount: 0,
  };
  const FSObj =
    plans.length &&
    plans[0].services.find((service) => service.serviceName === "Fertility Services (IVF, Egg Freezing)");
  if (FSObj) {
    FSInitialValues.FSType = FSObj.maternityCopayDetails.maxUnitsCoveredUnit
      ? FSObj.maternityCopayDetails.maxUnitsCoveredUnit
      : "units";
    if (FSInitialValues.FSType === "amount") {
      FSInitialValues.FSMaxAmount = FSObj.maternityCopayDetails.maxUnitsCovered;
    } else {
      FSInitialValues.FSMaxUnits = FSObj.maternityCopayDetails.maxUnitsCovered;
    }
  }

  const plansEndDate = moment(tableMap[0].startDate).add(12, "months");

  return {
    //step 1
    [planOptions.name]: tableMap.length,
    [withMultiplePremiumTiers.name]: numberOfTiers > 1,
    [premiumTiersNumber.name]: numberOfTiers,
    [selectedPremiumTier.name]: startingPremiumTier || report[option]?.premiumTier || 1,
    [premiumTier.name]: tableMap[0].tiers[startingPremiumTier || report[option]?.premiumTier || 1][groupType],
    [withWaiverPayment.name]: numberOfWaivers > 0 && Object.keys(waiversObj).some((key) => waiversObj[key].value !== 0),
    [waiverTiersNumber.name]: numberOfWaivers || 1,
    [selectedWaiverTier.name]: startingWaiverTier || report[option]?.waiverTier || 1,
    [waivers.name]: plans[0] && plans[0].premium && plans[0].premium.waivers ? plans[0].premium.waivers : {},
    [withSpouseSurcharge.name]: plans[0] ? !!plans[0].premium.spousalSurcharge : false,
    [spousalSurcharge.name]: plans[0] && plans[0].premium?.spousalSurcharge ? plans[0].premium.spousalSurcharge : 0,
    [spousalSurchargeFrequency.name]:
      plans[0] && plans[0].premium?.spousalSurchargeFrequency ? plans[0].premium.spousalSurchargeFrequency : 1,
    [withTobaccoSurcharge.name]: plans[0] ? !!plans[0].premium.tobaccoSurcharge : false,
    [tobaccoSurcharge.name]: plans[0] && plans[0].premium?.tobaccoSurcharge ? plans[0].premium.tobaccoSurcharge : 0,
    [tobaccoSurchargeFrequency.name]:
      plans[0] && plans[0].premium?.tobaccoSurchargeFrequency ? plans[0].premium.tobaccoSurchargeFrequency : 1,
    [costFor.name]: groupType,
    [negativeValue.name]: false,
    [outOfNetworkCoverage.name]: "yes",
    [table.name]: tableMap,
    [deductibles.name]: [
      {
        inNetwork: tableMap[0].inNetworkIndividual,
        outOfNetwork: tableMap[0].outOfNetworkIndividual,
      },
      {
        inNetwork: tableMap[0].inNetworkFamily,
        outOfNetwork: tableMap[0].outOfNetworkFamily,
      },
    ],
    //step 2
    [frequency.name]: tableMap[0].frequency,
    //step 3
    [startDate.name]: tableMap[0].startDate,
    [showIndividualInFamilyDeductibles.name]:
      plans.filter(
        (plan) =>
          plan.inNetwork.family.individualDeductible != null || plan.outOfNetwork.family.individualDeductible != null,
      ).length > 0,
    //step 4
    [showIndividualInFamilyOOP.name]:
      plans.filter(
        (plan) => plan.inNetwork.family.individualOopMax != null || plan.outOfNetwork.family.individualOopMax != null,
      ).length > 0,
    [OOPStarts.name]: "",
    //step 5
    [subjectToDeductible.name]: plans.every((plan) => plan.services[0].maternityCopayDetails.subjToDeductible),
    //step 6
    [enterAdvancedDetails.name]: true,
    [serviceList.name]: [...new Set(serviceNames.filter((serviceName) => serviceName !== "Preventive Care"))].map(
      (service) => {
        return {
          name: service,
          value: service,
        };
      },
    ),
    //step 7
    [group.name]: displayName || tableMap[0].group,
    [dontAddMoreCategories.name]: true,
    //step Advanced
    [notSureAboutThese.name]: false,
    [showNotSoSureAboutThese.name]: false,
    isStale: reportToVerify && Math.abs(plansEndDate.diff(moment(), "months")) >= 12,
    ...FSInitialValues,
  };
};
