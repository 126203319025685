import React from "react";
import { Card } from "antd";

const ListAllGroupsPanel = ({ groups }) => {
  const visibleGroups = groups.filter((group) => group.canBeSeen);
  const hiddenGroups = groups.filter((group) => !group.canBeSeen);

  const bestGroups = visibleGroups
    .filter((group) => group.prestigeRanking > 0 && group.logoUrl)
    .sort((a, b) => a.prestigeRanking - b.prestigeRanking);

  const newLength = Math.floor((bestGroups.length + 1) / 5) * 5 - 1;
  bestGroups.length = newLength
    ? newLength > 4
      ? Math.floor((bestGroups.length + 1) / 5) * 5 - 1
      : bestGroups.length
    : 0;

  const otherGroups = visibleGroups
    .filter((group) => !bestGroups.find((bestGroup) => bestGroup?.name === group?.name))
    .sort((a, b) => a.name.localeCompare(b.name));

  return (
    <div style={{ display: "flex", gap: "20px" }}>
      <Card title="Visible Groups" style={{ flex: 1 }}>
        {!groups.length && <div>loading groups...</div>}
        {bestGroups.map((group, idx) => (
          <div key={`best-${idx}`}>
            {group.name} - {group.displayName} - {group.masterReports} - {group.prestigeRanking}
          </div>
        ))}
        {bestGroups.length > 0 && otherGroups.length > 0 && <div>------------------------------------------</div>}
        {otherGroups.map((group, idx) => (
          <div key={`other-${idx}`}>
            {group.name} - {group.displayName} - {group.masterReports} - {group.prestigeRanking}
          </div>
        ))}
      </Card>

      <Card title="Hidden Groups" style={{ flex: 1 }}>
        {!groups.length && <div>loading groups...</div>}
        {hiddenGroups
          .sort((a, b) => a.name.localeCompare(b.name))
          .map((group, idx) => (
            <div key={idx}>
              {group.name} - {group.displayName} - {group.masterReports} - {group.prestigeRanking}
            </div>
          ))}
      </Card>
    </div>
  );
};

export default ListAllGroupsPanel;
