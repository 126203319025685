import React, { useState, useEffect } from "react";
import GroupVerificationFooter from "./footer";
import Questions from "./questions";
import JobOfferQuestions from "./jobOfferQuestions";
import CurrentEmployee from "./currentEmployee";
import VerifyCode from "./verifyCode";
import DatabaseEmailVerification from "./databaseEmailVerification";
import { getAllGroups } from "services/api/groups.api";
import styled from "styled-components";

const Wrapper = styled.div`
  padding: 5% 25% 0 25%;
  width: 100%;
  overflow: hidden;
  @media (max-width: 1800px) {
    padding: 5% 20% 0 20%;
  }
  @media (max-width: 1600px) {
    padding: 5% 17.5% 0 17.5%;
  }
  @media (max-width: 1280px) {
    padding: 5% 10% 0 10%;
  }
  @media (max-width: 900px) {
    padding: 5% 5% 0 5%;
  }
`;

const GroupVerificationSteps = ({ title, group, groupObj }) => {
  const [option, setOption] = useState("currentEmployee");
  const [jobOfferOption, setJobOfferOption] = useState("upload");
  const [step, setStep] = useState(0);
  const [previousStep, setPreviousStep] = useState(0);
  const [email, setEmail] = useState("");
  const [canSendEmail, setCanSendEmail] = useState(false);
  const [code, setCode] = useState("");

  useEffect(() => {
    setJobOfferOption(groupObj?.verifyJobOfferAgainstDatabase ? "verificationEmail" : "verificationCode");
  }, [groupObj]);

  return (
    <Wrapper>
      {option === "currentEmployee" ? (
        <div className={`step-${step}`}>
          <Questions title={title} step={step} option={option} setOption={setOption} />
          {groupObj.verifyWorkEmailAgainstDatabase ? (
            <DatabaseEmailVerification
              title={"Please enter your work email address."}
              subtitle={
                "We'll use this to verify your insurance rates, but we'll never email you at work to ask about your healthcare."
              }
              step={step}
              previousStep={previousStep}
              setEmail={setEmail}
              setCanSendEmail={setCanSendEmail}
              group={group}
              groupObj={groupObj}
              type={"currentEmployee"}
            />
          ) : (
            <CurrentEmployee
              step={step}
              previousStep={previousStep}
              group={group}
              groupObj={groupObj}
              setEmail={setEmail}
              setCanSendEmail={setCanSendEmail}
            />
          )}
          <VerifyCode
            title={"Check your email"}
            subtitle={
              <>
                Enter the verification code we sent to <span style={{ fontWeight: "bold" }}>{email}</span>. The code
                will expire shortly, so enter it soon.
              </>
            }
            step={step}
            setCode={setCode}
          />
        </div>
      ) : (
        <div className={`step-${step}`}>
          <Questions title={title} step={step} option={option} setOption={setOption} />
          <JobOfferQuestions
            step={step}
            previousStep={previousStep}
            jobOfferOption={jobOfferOption}
            setJobOfferOption={setJobOfferOption}
            groupObj={groupObj}
          />
          {jobOfferOption === "verificationEmail" ? (
            <DatabaseEmailVerification
              title={"Please enter your email address."}
              step={step}
              previousStep={previousStep}
              setEmail={setEmail}
              setCanSendEmail={setCanSendEmail}
              group={group}
              groupObj={groupObj}
              type={"jobOffer"}
            />
          ) : (
            <VerifyCode title={"Please enter your verification code below to proceed."} step={step} setCode={setCode} />
          )}
        </div>
      )}
      <GroupVerificationFooter
        option={option}
        jobOfferOption={jobOfferOption}
        step={step}
        setStep={setStep}
        setPreviousStep={setPreviousStep}
        email={email}
        canSendEmail={canSendEmail}
        code={code}
        group={group}
        groupObj={groupObj}
      />
    </Wrapper>
  );
};

export default GroupVerificationSteps;
