import React from "react";
import { useFormikContext } from "formik";
import { Checkbox } from "antd";
import { CheckboxWithWaiverPayment } from "./CheckboxWithWaiverPayment";
import { CheckboxWithSpouseSurcharge } from "./CheckboxWithSpouseSurcharge";
import { CheckboxWithTobaccoSurcharge } from "./CheckboxWithTobaccoSurcharge";
import { CheckboxWithMultiplePremiumTiers } from "./CheckboxWithMultiplePremiumTiers";
import { CustomVerticalSpace } from "./utils";
import PropTypes from "prop-types";

const AdvancedOptionsItems = [
  {
    Component: CheckboxWithWaiverPayment,
    name: "withWaiverPayment",
  },
  {
    Component: CheckboxWithSpouseSurcharge,
    name: "withSpouseSurcharge",
  },
  {
    Component: CheckboxWithTobaccoSurcharge,
    name: "withTobaccoSurcharge",
  },
  {
    Component: CheckboxWithMultiplePremiumTiers,
    name: "withMultiplePremiumTiers",
  },
];

const AdvancedOptionsToggle = ({ isOpen, setIsOpen }) => {
  return (
    <div className="form" style={{ padding: 4 }}>
      <Checkbox label="Show advanced options" onChange={() => setIsOpen(!isOpen)} checked={isOpen}>
        Show advanced options
      </Checkbox>
    </div>
  );
};
AdvancedOptionsToggle.propTypes = {
  isOpen: PropTypes.bool.isRequired,
  setIsOpen: PropTypes.func.isRequired,
};

const AdvancedOptionsContent = ({ isEnabled }) => {
  const { values } = useFormikContext();
  const OptionsToShow = AdvancedOptionsItems.filter(({ name }) => values[name] || isEnabled);

  return (
    <CustomVerticalSpace direction="column" gap={4}>
      {OptionsToShow.map(({ Component, name }) => (
        <Component key={name} />
      ))}
    </CustomVerticalSpace>
  );
};
AdvancedOptionsContent.propTypes = {
  isEnabled: PropTypes.bool.isRequired,
};

export const AdvancedOptions = {
  Toggle: AdvancedOptionsToggle,
  Content: AdvancedOptionsContent,
};
