import React, { useState, useEffect } from "react";
import styled from "styled-components";
import {
  MyPlansMiddleWrapper,
  MyPlansMiddleWrapperInsuranceCompanies,
  MyPlansMiddleWrapperTags,
  MyPlansBox,
} from "components";
import {
  ViewModal,
  SelectAddTypeModal,
  AddManuallyModal,
  TerminateManuallyModal,
  JobOffersModal,
  UploadModal,
  ResultsModal,
  FixEmployeesModal,
  NewFileFormatModal,
  FixCensusModal,
  DatePickerModal,
  ExportEmployeesModal,
  MedicalElectionResultsModal,
  FixMedicalElectionModal,
} from "./modals";
import { message, Form } from "antd";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import Actions from "store/plan/actions";
import { getToken } from "services/utils";
import { getPlansArr } from "services/api";
import {
  importEmployeesCheckHeaders,
  uploadEmployeesJSON,
  getPremiumTiers,
  checkEmployeesWithMissingPremiumTier,
  exportEmployees,
  uploadMedicalElectionJSON,
  importMedicalElectionCheckPlans,
} from "services/api/groups.api";
import * as XLSX from "xlsx/xlsx.mjs";
import _ from "lodash";
import moment from "moment";

const Wrapper = styled.div`
  font-family: 15px;
  color: #123;
  padding: 20px;
  padding-top: 0;
  background-color: ${({ withBG }) => (withBG ? "#d9f3f1" : "none")};
`;

const HeaderWrapper = styled.div`
  color: #123;
  width: 50vw;
  align-items: center;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  margin-left: auto;
  margin-right: auto;
  @media (max-width: 480px) {
    width: 100%;
    margin: 25px auto 0 auto;
  }
`;

const Title = styled.h1`
  color: black;
  text-align: center;
  font-size: ${({ bigTitle }) => (bigTitle ? "2.5em" : "1.2em")};
  ${({ bigTitle }) => bigTitle && `font-size: 2.5em;`};
  line-height: 1.4em;
  font-weight: 900;
  margin: 5px 35px;

  @media (max-width: 1300px) {
    line-height: 1.5em;
  }

  @media (max-width: 480px) {
    font-size: 1.2em;
    padding: 1px 2px;
  }
`;

const FlexWrapper = styled.div`
  display: flex;
  flex-direction: ${(props) => (props.col ? "column" : "row")};
  align-items: ${(props) => (props.align ? props.align : "center")};
  justify-content: ${(props) => (props.justify ? props.justify : "center")};
  width: 100%;
  flex-wrap: ${(props) => (props.wrap ? "wrap" : "nowrap")};
`;

const MyPlansDetailsCards = ({ group, setGroup, report, networksGrid }) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const id = networksGrid?._id || report?._id;
  const formatDate = (date) =>
    `${("0" + (date.getMonth() + 1)).slice(-2)}/${("0" + date.getDate()).slice(-2)}/${date.getFullYear()}`;
  const year = (report && report?.year ? report.year : report?.dates[0].split("/")[2]) || networksGrid?.year;
  const [numberOfServicesAvailable, setNumberOfServicesAvailable] = useState(0);
  const isComplete = report && report.year && numberOfServicesAvailable >= 9;

  const [form] = Form.useForm();
  const [modal, setModal] = useState(false);
  const [modalOption, setModalOption] = useState("view");
  const [employeesFileList, setEmployeesFileList] = useState([]);
  const [uploadType, setUploadType] = useState("");
  const [modalDate, setModalDate] = useState(new Date());
  const [exportColumnsOption, setExportColumnsOption] = useState("all");
  const [exportColumns, setExportColumns] = useState([
    "employee id",
    "email",
    "premium tier",
    "hire date",
    "termination date",
  ]);
  const [premiumTiers, setPremiumTiers] = useState([]);
  const [employeesAdded, setEmployeesAdded] = useState([]);
  const [currentEmployees, setCurrentEmployees] = useState([]);
  const [newHires, setNewHires] = useState([]);
  const [employeesRemoved, setEmployeesRemoved] = useState([]);
  const [employeesWithWrongRole, setEmployeesWithWrongRole] = useState([]);
  const [employeesNotInCensus, setEmployeesNotInCensus] = useState([]);
  const [employeesMerged, setEmployeesMerged] = useState([]);
  const [wrongRolesAdjustments, setWrongRolesAdjustments] = useState({});
  const [employeesNotInCensusAdjustments, setEmployeesNotInCensusAdjustments] = useState({});
  const [employeesWithMissingPremiumTier, setEmployeesWithMissingPremiumTier] = useState({
    currentEmployeeMap: [],
    formerEmployeeMap: [],
    jobOfferMap: [],
    wrongRoles: [],
  });
  const [employeesNotInMedicalElection, setEmployeesNotInMedicalElection] = useState([]);
  const [employeesNotFound, setEmployeesNotFound] = useState([]);
  const [medicalElectionPlans, setMedicalElectionPlans] = useState([]);
  const [medicalElectionWrongPlans, setMedicalElectionWrongPlans] = useState([]);
  const [medicalElectionWrongPlansAdjustments, setMedicalElectionWrongPlansAdjustments] = useState({});

  const censusHeaderList =
    premiumTiers.length > 1
      ? ["Email", "Premium Tier", "Employee ID", "Hire Date", "Termination Date"]
      : ["Email", "Employee ID", "Hire Date", "Termination Date"];
  const medicalElectionHeaderList = [
    "Email",
    "First Name",
    "Last Name",
    "Benefit Plan Name",
    "Coverage Tier",
    "Employee ID",
    "Hire Date",
  ];
  const [jsonToSubmit, setJsonToSubmit] = useState([]);
  const [newFileFormatHeaders, setNewFileFormatHeaders] = useState([]);
  const [newFileFormatHeadersAdjustments, setNewFileFormatHeadersAdjustments] = useState({});
  const [dataContainsHeaders, setDataContainsHeaders] = useState(false);

  useEffect(() => {
    const countServices = async () => {
      if (!report) {
        return;
      }
      const token = await getToken();
      const { data } = await getPlansArr(report.birthParent.planIds, token, false);
      const plans = data.plansArr;
      setNumberOfServicesAvailable(plans[0].services.length);
    };
    countServices();
  }, []);

  useEffect(() => {
    const fetchDataFromGroup = async () => {
      const token = await getToken();
      const premiumTiersResponse = await getPremiumTiers({
        name: group.name,
        year: year,
        reportId: report._id,
        token,
      });
      if (premiumTiersResponse.status === 200 && premiumTiersResponse.data) {
        setPremiumTiers(premiumTiersResponse.data);
      }

      const missingPremiumTiersResponse = await checkEmployeesWithMissingPremiumTier({
        name: group.name,
        year: year,
        reportId: report._id,
        token,
      });

      if (missingPremiumTiersResponse.status === 200 && missingPremiumTiersResponse.data) {
        setEmployeesWithMissingPremiumTier(missingPremiumTiersResponse.data);
      }
    };
    if (group && report) {
      fetchDataFromGroup();
    }
  }, [group]);

  useEffect(() => {
    if (
      employeesWithMissingPremiumTier.currentEmployeeMap.length +
        employeesWithMissingPremiumTier.formerEmployeeMap.length +
        employeesWithMissingPremiumTier.jobOfferMap.length >
        0 &&
      isComplete &&
      !modal
    ) {
      setModalOption("fix-employees");
      setModal(true);
    }
  }, [employeesWithMissingPremiumTier]);

  const getDaysDifference = (date1, date2) => {
    const diffTime = Math.abs(date2 - date1);
    return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
  };

  useEffect(() => {
    const employees = employeesAdded.map((employee) => ({
      ...employee,
      jsDate: new Date(employee.date),
    }));
    setCurrentEmployees(employees.filter((employee) => getDaysDifference(employee.jsDate, new Date()) >= 30));
    setNewHires(employees.filter((employee) => getDaysDifference(employee.jsDate, new Date()) < 30));
  }, [employeesAdded]);

  function getDataRange(data) {
    const dataWithValues = _.pickBy(data, (value) => !!value.v);
    const cellNamesWithValues = _.keys(dataWithValues);
    const cellsWithValues = cellNamesWithValues.map((cell) => XLSX.utils.decode_cell(cell));
    const maxRow = _.max(cellsWithValues.map((cell) => cell.r));
    const maxColumn = _.max(cellsWithValues.map((cell) => cell.c));
    const lastCellName = XLSX.utils.encode_cell({ c: maxColumn, r: maxRow });
    return `A1:${lastCellName}`;
  }

  const onInsertFile = (event) => {
    const file = event.file;
    setEmployeesFileList([file]);
    if (
      file.type === "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet" &&
      event.fileList.length > 0
    ) {
      file.arrayBuffer().then((res) => {
        try {
          const jsonList = [];
          const data = new Uint8Array(res);
          const workbook = XLSX.read(data, { type: "array" });
          workbook.SheetNames.forEach((sheetName) => {
            let worksheet = workbook.Sheets[sheetName];
            worksheet["!ref"] = getDataRange(worksheet);
            const jsonData = XLSX.utils.sheet_to_json(worksheet, { raw: false, defval: null });
            jsonList.push(jsonData);
          });
          if (jsonList.length > 0) {
            setJsonToSubmit(jsonList);
            if (uploadType === "census" || uploadType === "medicalElection") {
              setModalOption("date-picker");
            } else {
              checkHeaders(jsonList);
            }
          }
        } catch (err) {
          console.error(err);
          message.error(err.message);
        }
      });
    } else if (file.type === "text/csv" && event.fileList.length > 0) {
      const reader = new FileReader();

      reader.onload = (e) => {
        const text = e.target.result;
        const json = csvToJson(text);
        // csvs are always a single tab, but function expects an array of tabs, hence the wrapping
        setJsonToSubmit([json]);
        if (uploadType === "census" || uploadType === "medicalElection") {
          setModalOption("date-picker");
        } else {
          checkHeaders([json]);
        }
      };

      reader.readAsText(file);
    } else {
      setEmployeesAdded([]);
      setEmployeesRemoved([]);
      setEmployeesWithWrongRole([]);
      setEmployeesMerged([]);
    }
  };

  const csvToJson = (csv) => {
    const lines = csv.split("\n");
    const result = [];
    const headers = lines[0].split(",");

    for (let i = 1; i < lines.length; i++) {
      const obj = {};
      const currentLine = lines[i].split(",");

      headers.forEach((header, j) => {
        obj[header.trim()] = currentLine[j] ? currentLine[j].trim() : "";
      });

      result.push(obj);
    }

    return result;
  };

  const checkHeaders = async (json) => {
    try {
      const token = await getToken();
      const { status, data } = await importEmployeesCheckHeaders({ json, token });
      if (status === 200) {
        if (data === "quinnipiac") {
          submitEmployeeData(json);
        } else if (data) {
          setNewFileFormatHeaders(data);
          setModalOption("new-file-format");
        }
      }
    } catch (e) {
      console.log(e);
      message.error("Error uploading file");
    }
  };

  const submitEmployeeData = async (jsonList) => {
    try {
      const token = await getToken();
      const response = await uploadEmployeesJSON({
        json: jsonList,
        name: group.name,
        reportId: report._id,
        year,
        isCensus: uploadType === "census",
        censusDate: modalDate,
        token,
      });
      if (response.status === 200 && response.data) {
        setEmployeesAdded(response.data.employeesAdded);
        setEmployeesRemoved(response.data.employeesRemoved);
        setEmployeesWithWrongRole(response.data.employeesWithWrongRole);
        setEmployeesNotInCensus(response.data.employeesNotInCensus);
        setEmployeesMerged(response.data.employeesMerged);
        if (response.data.employeesNotInCensus.length > 0) {
          setModalOption("fix-census");
        } else if (
          response.data.employeesAdded.length +
            response.data.employeesRemoved.length +
            response.data.employeesWithWrongRole.length >
          0
        ) {
          setModalOption("results");
        } else {
          closeModalCleanStates();
          message.info("No changes detected");
        }
      }
    } catch (e) {
      console.log(e);
      message.error("Error uploading file");
    }
  };

  const medicalElectionCheckPlans = async (jsonList) => {
    try {
      const token = await getToken();
      const response = await importMedicalElectionCheckPlans({
        json: jsonList,
        name: group.name,
        token,
      });

      if (response.status === 200 && response.data) {
        const medicalElectionPlans = response.data.medicalElectionPlans;
        const wrongPlanNames = response.data.wrongPlanNames;
        if (wrongPlanNames.length) {
          setMedicalElectionPlans(medicalElectionPlans);
          setMedicalElectionWrongPlans(wrongPlanNames);
          setJsonToSubmit(jsonList);
          setModalOption("fix-medical-election");
        } else {
          submitMedicalElectionData(jsonList);
        }
      }
    } catch (e) {
      console.log(e);
      message.error("Error uploading file");
    }
  };

  const submitMedicalElectionData = async (jsonList) => {
    try {
      if (!jsonList) jsonList = jsonToSubmit;
      const token = await getToken();
      const response = await uploadMedicalElectionJSON({
        json: jsonList,
        name: group.name,
        date: modalDate,
        adjustments: medicalElectionWrongPlansAdjustments,
        token,
      });
      if (response.status === 200 && response.data) {
        setGroup(response.data.group);
        const employeesNotFound = response.data.employeesNotFound;
        const employeesNotInMedicalElection = response.data.employeesNotInMedicalElection;
        if (employeesNotFound.length + employeesNotInMedicalElection.length > 0) {
          setEmployeesNotFound(employeesNotFound);
          setEmployeesNotInMedicalElection(employeesNotInMedicalElection);
          setModalOption("medical-election-results");
        } else {
          closeModalCleanStates();
          message.info("Medical Election data uploaded successfully");
        }
      }
    } catch (e) {
      console.log(e);
      message.error("Error uploading file");
    }
  };

  const closeModalCleanStates = () => {
    setModal(false);
    setEmployeesFileList([]);
    setEmployeesAdded([]);
    setEmployeesRemoved([]);
    setEmployeesWithWrongRole([]);
    setEmployeesMerged([]);
    setNewFileFormatHeaders([]);
    setNewFileFormatHeadersAdjustments({});
    setEmployeesNotInCensus([]);
    setEmployeesNotInCensusAdjustments({});
    setModalDate(new Date());
    setUploadType("");
    setEmployeesNotFound([]);
    setEmployeesNotInMedicalElection([]);
    setJsonToSubmit([]);
  };

  const downloadLink = (href) => {
    const link = document.createElement("a");
    link.href = href;
    link.setAttribute("download", `${group.name}_${moment(modalDate).format("YYYYMMDD")}.xlsx`);
    document.body.appendChild(link);
    link.click();
    link.parentNode.removeChild(link);
  };

  const handleExportData = async () => {
    try {
      if (!group) return message.error("No group selected");
      const token = await getToken();
      const { status, data } = await exportEmployees({
        name: group.name,
        date: modalDate,
        year,
        columns: exportColumnsOption === "custom" ? exportColumns : null,
        token,
      });
      if (status === 200) {
        const href = window.URL.createObjectURL(data);
        downloadLink(href);
        setModalOption("view");
      } else {
        message.error("Internal Server Error");
      }
    } catch (error) {
      message.error("Unknown Error");
      console.log("error", error);
    }
  };

  return (
    <Wrapper>
      <HeaderWrapper>
        <Title bigTitle={true}>{group.displayName}</Title>
        <MyPlansMiddleWrapper
          imageSrc={group.logoUrl}
          year={year}
          monthsRange={
            (report &&
              `${new Date(report.dates[0]).toLocaleString("default", { month: "long" })} - ${new Date(
                report.dates[1],
              ).toLocaleString("default", { month: "long" })}`) ||
            "January - December"
          }
        />
      </HeaderWrapper>
      <FlexWrapper wrap>
        <MyPlansBox
          headerTitle={networksGrid ? "COMPLETE" : "NOT YET STARTED"}
          headerDate={networksGrid ? formatDate(new Date(networksGrid.updatedAt)) : null}
          title={"Insurance Networks"}
          middleWrapper={<MyPlansMiddleWrapperInsuranceCompanies />}
          buttons={
            networksGrid
              ? [
                  {
                    text: "VIEW",
                    href: `/networks/${id}`,
                  },
                  {
                    text: "EDIT",
                    onClick: () => {
                      localStorage.removeItem("networksForm");
                      dispatch(Actions.Creators.changeCurrent(0));
                      history.push("/edit-networks", {
                        gridId: id,
                      });
                    },
                  },
                ]
              : [
                  {
                    text: "ENTER DETAILS",
                    onClick: () => {
                      localStorage.removeItem("networksForm");
                      dispatch(Actions.Creators.changeCurrent(0));
                      history.push("/add-networks", {
                        gridId: id,
                      });
                    },
                  },
                  {
                    text: "UPGRADE FOR HELP",
                    href: "https://calendly.com/predictabill/help",
                  },
                ]
          }
        />
        <MyPlansBox
          headerTitle={report ? "COMPLETE" : "NOT YET STARTED"}
          headerDate={report ? report.fulfilledDate : null}
          title={"Plan Design"}
          middleWrapper={<MyPlansMiddleWrapperTags tags={["PPO", "EPO", "HDHP", "HMO"]} />}
          buttons={
            report
              ? [
                  {
                    text: "VIEW",
                    href: `/optimizeV2/${id}`,
                  },
                  {
                    text: "EDIT",
                    onClick: () => {
                      localStorage.removeItem("form");
                      dispatch(Actions.Creators.changeCurrent(0));
                      history.push(`/edit-plan`, {
                        reportId: report._id,
                        returnToDetailsPage: true,
                        activeTab: 2,
                        option: "birthParent",
                        displayName: group.displayName,
                        group: group.name,
                        groupObj: group,
                        year: year,
                      });
                    },
                  },
                ]
              : [
                  {
                    text: "ENTER DETAILS",
                    onClick: () => {
                      localStorage.removeItem("form");
                      dispatch(Actions.Creators.changeCurrent(0));
                      history.push("/create-plan", {
                        customReportId: id,
                        displayName: group.displayName,
                        group: group.name,
                        option: "birthParent",
                        groupObj: group,
                      });
                    },
                  },
                  {
                    text: "UPGRADE FOR HELP",
                    href: "https://calendly.com/predictabill/help",
                  },
                ]
          }
        />
        <MyPlansBox
          headerTitle={report ? (numberOfServicesAvailable >= 9 ? "COMPLETE" : "IN PROGRESS") : "NOT YET STARTED"}
          title={"Coverage Details"}
          middleWrapper={<MyPlansMiddleWrapperTags tags={["fertility", "therapy", "urgent care", "labwork"]} />}
          buttons={
            report
              ? [
                  {
                    text: "VIEW",
                    href: `/optimizeV2/${id}`,
                  },
                  {
                    text: "EDIT",
                    onClick: () => {
                      dispatch(Actions.Creators.changeCurrent(0));
                      localStorage.removeItem("servicesForm");
                      history.push(`/add-services`, {
                        reportId: report._id,
                        report: report,
                        returnToDetailsPage: true,
                        activeTab: 2,
                        isMasterReport: true,
                      });
                    },
                  },
                ]
              : [
                  {
                    text: "ENTER DETAILS",
                    disabled: true,
                  },
                  {
                    text: "UPGRADE FOR HELP",
                    disabled: true,
                  },
                ]
          }
        />

        {isComplete && (
          <MyPlansBox
            title={"Employees"}
            buttons={[
              {
                text: "VIEW",
                onClick: () => {
                  setModalOption("view");
                  setModal(true);
                },
              },
              {
                text: "UPLOAD",
                onClick: () => {
                  setModalOption("upload");
                  setModal(true);
                },
              },
              {
                text: "ADD",
                onClick: () => {
                  setModalOption("select-add-type");
                  setModal(true);
                },
              },
            ]}
          />
        )}
      </FlexWrapper>
      {modalOption === "view" && (
        <ViewModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          group={group}
          year={year}
          handleExport={() => {
            setModalOption("export-employees");
          }}
        />
      )}
      {modalOption === "select-add-type" && (
        <SelectAddTypeModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          setModalOption={setModalOption}
        />
      )}
      {modalOption === "add-manually" && (
        <AddManuallyModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          form={form}
          group={group}
          year={year}
          premiumTiers={premiumTiers}
          setGroup={setGroup}
        />
      )}
      {modalOption === "terminate-manually" && (
        <TerminateManuallyModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          form={form}
          group={group}
          year={year}
          premiumTiers={premiumTiers}
          setGroup={setGroup}
        />
      )}
      {modalOption === "job-offers" && (
        <JobOffersModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          form={form}
          group={group}
          year={year}
          premiumTiers={premiumTiers}
          setGroup={setGroup}
        />
      )}
      {modalOption === "upload" && (
        <UploadModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          onInsertFile={onInsertFile}
          employeesFileList={employeesFileList}
          employeesAdded={employeesAdded}
          employeesRemoved={employeesRemoved}
          employeesWithWrongRole={employeesWithWrongRole}
          setUploadType={setUploadType}
        />
      )}
      {modalOption === "results" && (
        <ResultsModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          group={group}
          setGroup={setGroup}
          year={year}
          employeesAdded={employeesAdded}
          setEmployeesAdded={setEmployeesAdded}
          employeesRemoved={employeesRemoved}
          setEmployeesRemoved={setEmployeesRemoved}
          employeesWithWrongRole={employeesWithWrongRole}
          setEmployeesWithWrongRole={setEmployeesWithWrongRole}
          employeesMerged={employeesMerged}
          wrongRolesAdjustments={wrongRolesAdjustments}
          setWrongRolesAdjustments={setWrongRolesAdjustments}
          newHires={newHires}
          currentEmployees={currentEmployees}
          premiumTiers={premiumTiers}
        />
      )}
      {modalOption === "fix-employees" && (
        <FixEmployeesModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          group={group}
          setGroup={setGroup}
          year={year}
          employeesWithMissingPremiumTier={employeesWithMissingPremiumTier}
          setEmployeesWithMissingPremiumTier={setEmployeesWithMissingPremiumTier}
          wrongRolesAdjustments={wrongRolesAdjustments}
          setWrongRolesAdjustments={setWrongRolesAdjustments}
          premiumTiers={premiumTiers}
        />
      )}
      {modalOption === "new-file-format" && (
        <NewFileFormatModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          jsonToSubmit={jsonToSubmit}
          dataContainsHeaders={dataContainsHeaders}
          setDataContainsHeaders={setDataContainsHeaders}
          headerList={uploadType === "medicalElection" ? medicalElectionHeaderList : censusHeaderList}
          newFileFormatHeaders={newFileFormatHeaders}
          newFileFormatHeadersAdjustments={newFileFormatHeadersAdjustments}
          setNewFileFormatHeadersAdjustments={setNewFileFormatHeadersAdjustments}
          submitFormJSON={uploadType === "medicalElection" ? medicalElectionCheckPlans : submitEmployeeData}
          premiumTiers={premiumTiers}
          uploadType={uploadType}
        />
      )}
      {modalOption === "fix-census" && (
        <FixCensusModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          group={group}
          setGroup={setGroup}
          employeesNotInCensus={employeesNotInCensus}
          employeesNotInCensusAdjustments={employeesNotInCensusAdjustments}
          setEmployeesNotInCensusAdjustments={setEmployeesNotInCensusAdjustments}
          employeesAdded={employeesAdded}
          employeesRemoved={employeesRemoved}
          employeesWithWrongRole={employeesWithWrongRole}
          setModalOption={setModalOption}
        />
      )}
      {modalOption === "date-picker" && (
        <DatePickerModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          modalDate={modalDate}
          setModalDate={setModalDate}
          title={uploadType === "medicalElection" ? "Medical Election Date" : "Census Date"}
          paragraph={`Please select the date for the ${
            uploadType === "medicalElection" ? "medical election" : "census"
          } report`}
          onOk={() => {
            checkHeaders(jsonToSubmit);
          }}
        />
      )}
      {modalOption === "export-employees" && (
        <ExportEmployeesModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          onOk={() => handleExportData()}
          modalDate={modalDate}
          setModalDate={setModalDate}
          exportColumns={exportColumns}
          setExportColumns={setExportColumns}
          exportColumnsOption={exportColumnsOption}
          setExportColumnsOption={setExportColumnsOption}
        />
      )}
      {modalOption === "medical-election-results" && (
        <MedicalElectionResultsModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          employeesNotFound={employeesNotFound}
          employeesNotInMedicalElection={employeesNotInMedicalElection}
        />
      )}
      {modalOption === "fix-medical-election" && (
        <FixMedicalElectionModal
          modal={modal}
          closeModalCleanStates={closeModalCleanStates}
          onOk={() => submitMedicalElectionData()}
          medicalElectionPlans={medicalElectionPlans}
          medicalElectionWrongPlans={medicalElectionWrongPlans}
          medicalElectionWrongPlansAdjustments={medicalElectionWrongPlansAdjustments}
          setMedicalElectionWrongPlansAdjustments={setMedicalElectionWrongPlansAdjustments}
        />
      )}
    </Wrapper>
  );
};

export default MyPlansDetailsCards;
