import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Actions from "store/report/actions";
import { RadioGroup, booleanToTextValue, textValueToBoolean } from "../components/RadioGroup";

export const TobaccoFilter = ({ report, plans }) => {
  const dispatch = useDispatch();
  const tobaccoOption = useSelector((state) => state.report.tobaccoOption);
  if (report.version === "v1") return null;

  const hasTobaccoSurcharge = plans?.some(
    (plan) => plan.premium.tobaccoSurcharge && plan.premium.tobaccoSurchargeFrequency,
  );
  if (!hasTobaccoSurcharge) return null;
  return (
    <RadioGroup
      name="tobacco-option"
      label="Tobacco Utilization"
      options={[
        {
          value: booleanToTextValue(true),
          label: "Tobacco User",
        },
        {
          value: booleanToTextValue(false),
          label: "Non Tobacco User",
        },
      ]}
      onChange={(value) => {
        dispatch(
          Actions.Creators.switchState({
            tobaccoOption: textValueToBoolean(value),
          }),
        );
      }}
      value={booleanToTextValue(tobaccoOption)}
      rightAligned
    />
  );
};
