import React from "react";
import { useFormikContext } from "formik";
import FormField from "components/molecules/FormField";
import SelectField from "components/molecules/SelectField";
import { surchargeWaiverFrequencyOptions } from "../utils/options";
import CheckboxField from "components/molecules/CheckboxField";
import { formatterNumber, parserNumber } from "utils/currency";
import { SpaceDivider } from "./utils";

export const CheckboxWithTobaccoSurcharge = () => {
  const { values } = useFormikContext();
  const { withTobaccoSurcharge } = values;

  return (
    <>
      <CheckboxField
        name="withTobaccoSurcharge"
        label="There is a surcharge for a person who smokes or uses tobacco to join these plans"
      />

      {withTobaccoSurcharge && (
        <>
          <FormField
            name="tobaccoSurcharge"
            type="number"
            label="Tobacco surcharge value"
            defaultValue="$"
            formatter={formatterNumber}
            parser={parserNumber}
            required
          />
          <SelectField
            name="tobaccoSurchargeFrequency"
            options={surchargeWaiverFrequencyOptions}
            label="Frequency"
            defaultValue={1}
          />
          <SpaceDivider />
        </>
      )}
    </>
  );
};
